import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./jobs.scss";

// images
import careerBannerImg from "../../../assets/images/careers-banner.png";

// icons
import { ReactComponent as MarkerIcon } from "../../../assets/icons/icon-marker.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/icon-arrow-right-black.svg";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useAppSelector } from "../../../redux/hooks";
import { getCareerList } from "../../../redux/pages/careerSlice";

const Jobs = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  const careerList = useAppSelector((state) => state.career.careerList);

  useEffect(() => {
    dispatch(getCareerList());
  }, []);

  return (
    <>
      <Header />
      {/* banner section */}
      <section className="jobs-banner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="banner-content">
                <p className="page-name">Career</p>
                <h1 className="banner-title">
                  <span className="primary-highlilght">Join Our Team</span>
                  and Shape the Future of Insurance Education.
                </h1>
                <p className="banner-description">
                  Discover fulfilling career paths at Assurance, where
                  innovation meets education. Explore diverse opportunities to
                  contribute to our mission of revolutionizing the insurance
                  landscape. Join us in shaping a future where knowledge
                  empowers and success is a shared journey.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-8 mx-md-auto ">
              <div className="right-img-wrapper">
                <img
                  src={careerBannerImg}
                  alt="banner image 1"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner section */}

      {/* values section */}
      <section className="values-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-content">
                <p className="section-name">Our Values</p>
                <h1 className="banner-title">
                  Upholding Excellence and Innovation in Every Career Path{" "}
                </h1>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="value-card">
                    <h5 className="value-card-title">Passion for Education</h5>
                    <p className="description">
                      We seek individuals who are genuinely passionate about
                      transforming the landscape of insurance education, driven
                      by a commitment to empowering learners with knowledge.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="value-card">
                    <h5 className="value-card-title">Passion for Education</h5>
                    <p className="description">
                      We seek individuals who are genuinely passionate about
                      transforming the landscape of insurance education, driven
                      by a commitment to empowering learners with knowledge.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 mx-sm-auto">
                  <div className="value-card">
                    <h5 className="value-card-title">Passion for Education</h5>
                    <p className="description">
                      We seek individuals who are genuinely passionate about
                      transforming the landscape of insurance education, driven
                      by a commitment to empowering learners with knowledge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* values section */}

      {/* position section */}
      <section className="position-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-content">
                <p className="section-name">Open Position</p>
                <h1 className="banner-title">
                  Explore Our Open Positions and Shape the Future of Insurance
                  Education with Us.
                </h1>
              </div>
            </div>
            {careerList.length === 0 ? 
              <div className="col-12">
                <a href="javascript:void(0);" className="roles-card">
                  <div className="content-wrap">
                    <h5 className="role-title">Currently There is no open postions</h5>
                    <div className="location-wrapper">
                    </div>
                  </div>
                  <span>
                    <ArrowRightIcon />
                  </span>
                </a>
              </div>
            : ''}
            {careerList?.map((career: any, index: any) => (
              <div className="col-12" key={index}>
                <Link to="/contact" className="roles-card">
                  <div className="content-wrap">
                    <h5 className="role-title">{career.title}</h5>
                    <div className="location-wrapper">
                      <span className="marker-icon">
                        <MarkerIcon />
                      </span>
                      <p className="location">{career.location}</p>
                    </div>
                  </div>
                  <span>
                    <ArrowRightIcon />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* position section */}

      <Footer />
    </>
  );
};

export default Jobs;
