import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../../common/interceptors/axiosConfig";

export interface courseState {
    errors: any;
    isLoading: boolean;
    faqList: any,
}
  
const initialState: courseState = {
    errors: null,
    isLoading: false,
    faqList: [],
};
const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setFaqs: (state, { payload }: PayloadAction<any>) => {
          console.log(payload, 'payload');
          
          state.faqList = payload;
        },
    }
});

export const getFagList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let URL = "/faq/index";
      const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: false, });
      if (response.data) {
        dispatch(setLoading(false));
        if (response.data.status && response.data.status === "success") {
          dispatch(setFaqs(response.data.faq));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors(error?.response));
    }
};

export const {
    setLoading,
    setErrors,
    setFaqs,
  } = faqSlice.actions;
  
  export default faqSlice.reducer;