import * as Yup from "yup";

export const financialAidRegisterValidationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    email: Yup.string()
        .required("Email field is required")
        .matches(
            /^[A-Z0-9._%+-]+@{1}[A-Z0-9.-]+\.{1}[A-Z]{2,4}$/i,
            "Invalid email format"
        ),
    mobile: Yup.string()
        .required("This field is required")
        .matches(
            /^\d{12,}$/,
            "Enter a valid mobile number with country code"
        ),
});