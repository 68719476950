import { combineReducers } from "@reduxjs/toolkit";
import loginSlice from "./pages/loginSlice";
import courseSlice from "./pages/courseSlice";
import profileSlice from "./pages/profileSlice";
import commonUiSlice from "./common/commonUiSlice";
import faqSlice from "./pages/faqSlice";
import careerSlice from "./pages/careerSlice";

const rootReducer = combineReducers({
    login: loginSlice,
    course: courseSlice,
    profile: profileSlice,
    common: commonUiSlice,
    faq: faqSlice,
    career: careerSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;