import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../../common/interceptors/axiosConfig";

export interface courseState {
    errors: any;
    isLoading: boolean;
    careerList: any,
}
  
const initialState: courseState = {
    errors: null,
    isLoading: false,
    careerList: [],
};
const careerSlice = createSlice({
    name: "career",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setCareer: (state, { payload }: PayloadAction<any>) => {
          state.careerList = payload;
      },
    }
});

export const getCareerList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let URL = "/career/index";
      const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: false, });
      if (response.data) {
        dispatch(setLoading(false));
        if (response.data.status && response.data.status === "success") {
          dispatch(setCareer(response.data?.career));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors(error?.response));
    }
};

export const {
    setLoading,
    setErrors,
    setCareer,
  } = careerSlice.actions;
  
  export default careerSlice.reducer;