import React, { useEffect, useState } from "react";
import "./header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { logout } from "../../redux/pages/loginSlice";

// logo
import assuranceLogo from "../../assets/logos/assurance-logo-navbar.svg";

// icons
import { ReactComponent as BellIcon } from "../../assets/icons/icon-bell.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/icon-message.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/icon-setting.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/icon-logout.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/icon-profile.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/icon-arrow-down.svg";
import { ReactComponent as RightChevronBlackIcon } from "../../assets/icons/icon-chevron-right-black.svg";
import { ReactComponent as LeftArrowBlackIcon } from "../../assets/icons/icon-arrow-left-black.svg";

import { useDispatch } from "react-redux";
import { menuList } from "../../redux/pages/courseSlice";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [courseMenuOpen, setCourseMenuOpen] = useState<boolean>(false);
  const [activeMenuProgram, setActiveMenuProgram] = useState<number>(0);
  const [activeMenuSubprogram, setActiveMenuSubprogram] = useState<number>(0);
  const [activeMenuSubject, setActiveMenuSubject] = useState<number>(0);
  const [isLoggedIn, setIsLoadedIn] = useState<boolean>(false);

  const [subprogrmList, setSubprogramList] = useState<any>([]);
  const [courseList, setCourseList] = useState<any>([]);

  
  const image = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  const [profileImage, setProfileImage] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();

  const profileImageInfo = useAppSelector(
    (state) => state.profile.profileDataUpdate
  );
  const menuInfo = useAppSelector((state) => state.course.menuData);

  useEffect(() => {
    setProfileImage(profileImageInfo);
  }, [profileImageInfo]);

  const settingsClick = () => {
    navigate("/settings");
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if(sessionStorage.getItem('studentToken')) {
      setIsLoadedIn(true);
    }
  }, [sessionStorage.getItem('studentToken')]);

  useEffect(() => {
    dispatch(menuList());
  }, []);

  useEffect(() => {
    if(menuInfo.length) {
      if(menuInfo[0]?.subprogram?.length) {
        setSubprogramList(menuInfo[0].subprogram);
        setCourseList(menuInfo[0]?.subprogram[0]?.subject);
      }
      else if(!menuInfo[0]?.subprogram?.length && menuInfo[0]?.subject?.length) {
        setSubprogramList(menuInfo[0]?.subject);
      }
    }
  }, [menuInfo]);

  const activeMenuChange = (index: number, subprogram: any, course: any) => {
    setActiveMenuProgram(index);
    if(subprogram.length){
      setSubprogramList(subprogram);
    }
    else if(course.length) {
      setSubprogramList(course);
    }
  }

  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-primary-2 ">
        <div className="container-lg align-items-stretch">
          {/* logo + hamburger menu */}
          <div className="d-flex align-items-center py-2">
            <button
              className="navbar-toggler me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className={`hamburger-menu-icon ${menuOpen ? "open" : ""}`}>
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
              </div>
            </button>
            <Link to={"/"} className="navbar-brand p-0">
              <img
                src={assuranceLogo}
                className="navbar-logo me-1 d-none d-md-block"
                alt="assurance-logo"
              />
              Assurance
            </Link>
          </div>

          {/* notification + avatar dropdown for mobile screen */}
          <div className="d-block d-md-none py-2 d-none">
            <div className="d-flex align-items-center h-100">
              {isLoggedIn ? 
                <>
                  <div className="btn-group notification-dropdown">
                    <button
                      style={{display: 'none'}}
                      type="button"
                      className="dropdown-toggle notification-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <BellIcon />
                      <div className="notification-dot">10</div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <div className="title-wrap">
                          <div className="underline">
                            <h5 className="dropdown-title">Notifications</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1 sfdfd</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item py-1" type="button">
                          <button className="btn btn-sm btn-link mx-auto">
                            See All
                          </button>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="btn-group avatar-dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle avatar-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://toppng.com/uploads/preview/donna-picarro-dummy-avatar-115633298255iautrofxa.png"
                        alt="user-avatar"
                      />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button className="dropdown-item" type="button">
                          <span className="me-2">
                            <SettingsIcon />
                          </span>
                          Settings
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <span className="me-2">
                            <LogoutIcon />
                          </span>
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              : ''}
            </div>
          </div>

          {/* navbar links */}
          <div
            className="collapse navbar-collapse mobile-menu"
            // className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav mx-auto h-100 align-items-md-center">
              <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              {isLoggedIn ?
                <>
                  <li className="nav-item">
                    <button
                      onClick={() => {
                        setCourseMenuOpen(true);
                        document.body.style.overflow = "hidden";
                      }}
                      className={`nav-link d-block d-md-none `}
                    >
                      Courses
                      <DownArrowIcon className="rotating-arrow arrow d-none d-md-block" />
                    </button>

                    <Link
                      to="/courses"
                      className={`nav-link d-none d-md-block ${
                        location.pathname === "/courses" ? "active" : ""
                      }`}
                      aria-current="page"
                    >
                      Courses
                      <DownArrowIcon className="rotating-arrow arrow" />
                    </Link>

                    {/* mobile menu */}
                    <div
                      className={`mobile-modal-menu ${
                        courseMenuOpen ? "d-block" : "d-none"
                      }`}
                    >
                      <div className="header-section">
                        <span
                          className="back-arrow"
                          onClick={() => {
                            setCourseMenuOpen(false);
                            document.body.style.overflow = "auto";
                          }}
                        >
                          <LeftArrowBlackIcon />
                        </span>
                        <h5 className="menu-title">Courses</h5>
                      </div>
                      <div className="body-section">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Insurance
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div
                                  className="accordion accordion-inner"
                                  id="accordionExample2"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseInnerOne"
                                        aria-expanded="true"
                                        aria-controls="collapseInnerOne"
                                      >
                                        Licentiate
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseInnerOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample2"
                                    >
                                      <div className="accordion-body">
                                        <button className="menu-item">
                                          <p>Course 1</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 2</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 3</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 4</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 5</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 6</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseInnerTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseInnerTwo"
                                      >
                                        Associate
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseInnerTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample2"
                                    >
                                      <div className="accordion-body">
                                        <button className="menu-item">
                                          <p>Course 1</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 2</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 3</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 4</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseInnerThree"
                                        aria-expanded="false"
                                        aria-controls="collapseInnerThree"
                                      >
                                        Fellowship
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseInnerThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample2"
                                    >
                                      <div className="accordion-body">
                                        <button className="menu-item">
                                          <p>Course 1</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 2</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 3</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                        <button className="menu-item">
                                          <p>Course 4</p>
                                          <span className="arrow-icon">
                                            <RightChevronBlackIcon />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* web menu */}
                    <div className="dropdown-menu">
                      <div className="row">
                        <div className="col-md-4">
                          <ul className="column-1">
                          {menuInfo?.map((course: any, index: number) => (
                            <li key={index} onClick={() => activeMenuChange(index, course.subprogram, course.subject)}>
                              <a href="#" className={`menu-item ${activeMenuProgram === index ? 'active' : ''}`}>
                                <div className="title-sec">
                                  <h6 className="title">{course.name}</h6>
                                  <span className="arrow-right">
                                    <RightChevronBlackIcon className="arrow-icon" />
                                  </span>
                                </div>
                              </a>
                            </li>
                          ))}
                            {/* <li>
                              <a href="" className="menu-item">
                                <div className="title-sec">
                                  <h6 className="title">Insurance</h6>
                                  <span className="arrow-right">
                                    <RightChevronBlackIcon className="arrow-icon" />
                                  </span>
                                </div>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                        {subprogrmList?.length ? 
                          <div className="col-md-4 border-l">
                            <ul className="column-2">
                              {subprogrmList?.map((course: any, index: number) => (
                                <li>
                                  <a href="#" className={`menu-item ${activeMenuSubprogram === index ? 'active' : ''}`}>
                                    <div className="title-sec">
                                      <h6 className="title">{course.name}</h6>
                                      <span className="arrow-right">
                                        <RightChevronBlackIcon className="arrow-icon" />
                                      </span>
                                    </div>
                                    <p className="description">
                                      {course.description}
                                    </p>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        :''}
                        {courseList?.length ? 
                          <div className="col-md-4 border-l">
                            <ul className="column-3">
                              {courseList?.map((course: any, index: number) => (
                                <li key={index}>
                                  <a href="" className="menu-item active">
                                    <div className="title-sec">
                                      <h6 className="title">{course?.name}</h6>
                                      <span className="arrow-right">
                                        <RightChevronBlackIcon className="arrow-icon" />
                                      </span>
                                    </div>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          :''}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/my-learnings"
                      className={`nav-link ${
                        location.pathname === "/my-learnings" ? "active" : ""
                      }`}
                    >
                      My Learnings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/cart"
                      className={`nav-link ${
                        location.pathname === "/cart" ? "active" : ""
                      }`}
                    >
                      Cart
                    </Link>
                  </li>
                </>
              :
                <>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className={`nav-link ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/careers"
                      className={`nav-link ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      className={`nav-link ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                    >
                      Contact Us
                    </Link>
                  </li>
                </>
              }
            </ul>
          </div>

          {/* notification + avatar dropdown for large screen */}
          <div className="d-none d-md-block py-2">
            <div className="d-flex align-items-center h-100">
              {isLoggedIn ? 
                <>
                  <div className="btn-group notification-dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle notification-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{display: 'none'}}
                    >
                      <BellIcon />
                      <div className="notification-dot">10</div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <div className="title-wrap">
                          <div className="underline">
                            <h5 className="dropdown-title">Notifications</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1 sfdfd</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button">
                          <div className="line">
                            <span className="me-2 circle">
                              <MessageIcon />
                            </span>
                            <p>Notification 1</p>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item py-1" type="button">
                          <Link
                            to="/notifications"
                            className="btn btn-sm btn-link mx-auto"
                          >
                            See All
                          </Link>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="btn-group avatar-dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle avatar-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <img src={profileImage}/> */}
                      {profileImage !== null ? (
                        <img
                          // src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                          src={profileImage}
                        />
                      ) : (
                        <UserIcon />
                      )}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <button
                          onClick={settingsClick}
                          className="dropdown-item"
                          type="button"
                        >
                          <span className="me-2">
                            <SettingsIcon />
                          </span>
                          Settings
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={logoutHandler}
                          className="dropdown-item"
                          type="button"
                        >
                          <span className="me-2">
                            <LogoutIcon />
                          </span>
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              : ''}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
