import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../../common/interceptors/axiosConfig";

export interface courseState {
    errors: any;
    isLoading: boolean;
}
  
const initialState: courseState = {
    errors: null,
    isLoading: false,
};
const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
    }
});

export const contactSubmit = (body: any, setIsSuccess: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let URL = "/contact/store";
      const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: false, });
      if (response.data) {
        dispatch(setLoading(false));
        if (response.data.status && response.data.status === "success") {
          setIsSuccess(true);
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrors(error?.response));
    }
};

export const {
    setLoading,
    setErrors,
  } = contactSlice.actions;
  
  export default contactSlice.reducer;