import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./contact.scss";

// images
import careerBannerImg from "../../../assets/images/careers-banner.png";

// icons
import { ReactComponent as MailIcon } from "../../../assets/icons/icon-email.svg";
import { ReactComponent as MailIconWhite } from "../../../assets/icons/icon-mail-white.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/icon-arrow-right-black.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/icon-phone.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/icon-user.svg";
import { ReactComponent as ExportIcon } from "../../../assets/icons/icon-export.svg";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { contactSubmit } from "../../../redux/pages/contactSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactValidationSchema } from "../../../common/validations/contactValidations";

const Contact = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(contactValidationSchema)
  });

  const onContactSubmit = (submitData: any) => {
    dispatch(contactSubmit(submitData, setIsSuccess));
  }

  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false);
      alert('Enquiry has been placed');
      reset({name: '', email: '', mobile: '', message: ''});
    }
  }, [isSuccess]);

  return (
    <>
      <Header />
      {/* contact section */}
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-6">
              <div className="banner-content mt-0 mt-md-5">
                <p className="page-name">Contact Us</p>
                <h1 className="banner-title">We would love to hear from you</h1>
                <p className="banner-description">
                  If you have any doubt or you are not sure about which courses
                  to take, please contact our team.
                </p>
                <a href="mailto:inof@assuranceacademy.net">
                  <button className="btn btn-primary mail-btn" type="button">
                    <span className="mail-icon me-1">
                      <MailIconWhite />
                    </span>
                    inof@assuranceacademy.net
                  </button>
                </a>
                <h3 className="subtitle">
                  Check out our FAQ section for the doubts you may have.
                </h3>
                <button className="btn btn-outline-primary" type="button" onClick={() => navigate('/')}>
                  <span className="arrow-expand-icon me-1">
                    <ExportIcon />
                  </span>
                  FAQ
                </button>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-6">
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(onContactSubmit)} action="">
                  <div className="form-control-wrap mb-3">
                    <label className="form-label">Name</label>
                    <div className="icon-form-control">
                      <div className="start-icon">
                        <UserIcon />
                      </div>
                      <input
                        className="form-control"
                        placeholder="Enter your name"
                        {...register("name")}
                        name="name"
                      />
                    </div>
                    <p className="validation-text">{errors.name?.message}</p>
                  </div>
                  <div className="form-control-wrap mb-3">
                    <label className="form-label">Email</label>
                    <div className="icon-form-control">
                      <div className="start-icon">
                        <MailIcon />
                      </div>
                      <input
                        className="form-control"
                        placeholder="Enter your email"
                        {...register("email")}
                        name="email"
                      />
                    </div>
                    <p className="validation-text">{errors.email?.message}</p>
                  </div>
                  <div className="form-control-wrap mb-3">
                    <label className="form-label">
                      Mobile Number <span>(with country code)</span>
                    </label>
                    <div className="icon-form-control">
                      <div className="start-icon">
                        <PhoneIcon />
                      </div>
                      <input
                        className="form-control"
                        placeholder="Enter your mobile"
                        {...register("mobile")}
                        name="mobile"
                      />
                    </div>
                    <p className="validation-text">{errors.mobile?.message}</p>
                  </div>
                  <div className="form-control-wrap mb-3">
                    <label className="form-label">Message </label>
                    <div className="icon-form-control">
                      <textarea
                        {...register("message")}
                        name="message"
                        rows={4}
                        placeholder="Enter your message"
                        className="form-control"
                        id="Message"
                      ></textarea>
                    </div>
                    <p className="validation-text">{errors.message?.message}</p>
                  </div>
                  <div className="pt-4">
                    <button
                      type="submit"
                      role="button"
                      className="btn btn-lg btn-primary w-100"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact section */}
      <Footer />
    </>
  );
};

export default Contact;
