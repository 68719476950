import React, { useEffect, useState } from "react";
import "./financial-aid-modal.scss";

// icons
import { ReactComponent as CircleCloseIcon } from "../../../assets/icons/icon-close-circle.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/icon-user.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/icon-email.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/icon-phone.svg";

// images
import trophyImage from "../../../assets/images/trophy-illustration.png";
import businessSupportIllustration from "../../../assets/images/business-support-illustration.svg";
import successCheckCircle from "../../../assets/images/success-check-circle.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { financialAidRegisterValidationSchema } from "../../../common/validations/financialAidValidations";
import { useDispatch } from "react-redux";
import { financialAidSubmit } from "../../../redux/pages/financialAidSlice";

interface FinancialAid{
  modalAction: any; 
};

const FinancialAidModal:React.FC<FinancialAid> = (props) => {
  const { modalAction } = props;
  const [step, setStep] = useState<number>(1);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (isSuccess) {
      setStep(3);
      setIsSuccess(false);
      reset({name: '', email: '', mobile: ''});
    }
  }, [isSuccess]);

  const modalClose = () => {
    setStep(1);
    modalAction(false);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(financialAidRegisterValidationSchema)
  });

  const onFinancialAidSubmit = (submitData: any) => {
    dispatch(financialAidSubmit(submitData, setIsSuccess));
  }

  return (
    <div
      className="modal fade show d-block financial-aid-modal"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog show modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header ">
            <button
              className="close-modal-btn ms-auto"
              data-bs-dismiss="modal"
              onClick={modalClose}
            >
              <CircleCloseIcon />
            </button>
          </div>
          <div className="modal-body">
            { step === 2 ? 
              /* step 2 */
              <div className="p-md-4">
                <div className="illustration-img mb-5 d-block d-md-none">
                  <img
                    src={businessSupportIllustration}
                    alt="business-support-illustration"
                  />
                </div>
                <h4 className="modal-title text-center text-md-start">
                  Financial Aid
                </h4>
                <p className="modal-desc text-center text-md-start">
                  Hey aspirants, Successfully complete the course within the
                  specified attempts, and you'll be eligible for an exclusive
                  cashback reward as a testament to your dedication and commitment
                  to mastering the material.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <form onSubmit={handleSubmit(onFinancialAidSubmit)} action="">
                      <div className="form-control-wrap mb-2">
                        <label className="form-label">Name</label>
                        <div className="icon-form-control">
                          <div className="start-icon">
                            <UserIcon />
                          </div>
                          <input
                            className="form-control"
                            placeholder="Enter your name"
                            {...register("name")}
                            name="name"
                          />
                        </div>
                        <p className="validation-text">{errors.name?.message}</p>
                      </div>
                      <div className="form-control-wrap mb-2">
                        <label className="form-label">Email</label>
                        <div className="icon-form-control">
                          <div className="start-icon">
                            <MailIcon />
                          </div>
                          <input
                            className="form-control"
                            placeholder="Enter your email"
                            {...register("email")}
                            name="email"
                          />
                        </div>
                        <p className="validation-text">{errors.email?.message}</p>
                      </div>
                      <div className="form-control-wrap mb-2">
                        <label className="form-label">
                          Mobile Number
                          <span className="sub-label ms-1">
                            (with country code)
                          </span>
                        </label>
                        <div className="icon-form-control">
                          <div className="start-icon">
                            <PhoneIcon />
                          </div>
                          <input
                            className="form-control"
                            placeholder="Enter your mobile number(912234567890)"
                            {...register("mobile")}
                            name="mobile"
                          />
                        </div>
                        <p className="validation-text">{errors.mobile?.message}</p>
                      </div>

                      <div className="mb-4">
                        <button
                          type="submit"
                          role="button"
                          className="btn btn-lg btn-primary w-100"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6 d-none d-md-block">
                    <div className="right-illustraion">
                      <img
                        src={businessSupportIllustration}
                        alt="business-support-illustration"
                      />
                    </div>
                  </div>
                </div>

                <p className="btm-description justify-content-start">
                  Fill the detail and we will contact you !
                </p>
              </div>
            : 
            step === 3 ?
              /* step 3 */
              <div className="d-flex align-items-center justify-content-center success-modal-container">
                <div>
                  <div className="illustration-success d-flex justify-content-center mb-2 mb-lg-4">
                    <img
                      src={successCheckCircle}
                      alt="success check circle"
                      className="success-img"
                    />
                  </div>
                  <h4 className="modal-title text-center">
                    Request Send Successfully
                  </h4>
                  <p className="modal-desc text-center">
                    Your request has been Successfully send. Our team will contact
                    you shortly regarding financial aid. Happy Learning!
                  </p>
                </div>
              </div>
            :
            <div>
              <div className="img-wrapper mb-4">
                <img src={trophyImage} alt="trophy-illustration" />
              </div>
              <h4 className="modal-title text-center">
                Earn 100% Cash Back with Our Course Completion Guarantee.
              </h4>
              <p className="modal-desc text-center">
                Hey aspirants, Successfully complete the course within the
                specified attempts, and you'll be eligible for an exclusive
                cashback reward as a testament to your dedication and commitment
                to mastering the material.
              </p>
              <div className="cards-wrapper">
                <div className="card-inner">
                  <h5 className="card-title">First Attempt</h5>
                  <p className="description">
                    Enjoy the benefits of a{" "}
                    <span className="highlight-text">100% cashback</span> offer
                    when you successfully complete the course on your first
                    attempt
                  </p>
                </div>
                <div className="card-inner">
                  <h5 className="card-title">Second Attempt</h5>
                  <p className="description">
                    Enjoy the benefits of a{" "}
                    <span className="highlight-text">50% cashback</span> offer
                    when you successfully complete the course on your second
                    attempt
                  </p>
                </div>
              </div>
              <p className="btm-description">
                Explore Financial Aid Options for Your Learning Journey.
                <button type="button" onClick={() => setStep(2)} className="btn btn-sm btn-link ms-1">
                  Apply Financial Aid
                </button>
              </p>
              <p className="terms">
                <button type="button" className="btn btn-sm btn-link me-1">
                  Terms & Conditions
                </button>
                applied.
              </p>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialAidModal;
